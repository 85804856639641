.card-uploaded-file {
  background-color: white;
  will-change: background-color;

  @media (hover: hover) {
    &:hover {
      background-color: $grey-lightest;
    }
  }

  &:focus {
    background-color: $grey-lightest;
  }

  .uploaded-file-data-name {
    text-decoration: none;
    will-change: text-decoration;
  }
}
