.input-select-result {
  .input-select-result-value {
    display: block;
    font: 400 1rem/1.5rem $font;
    padding: 0.75rem 1rem;
    min-height: 3rem;
    background-color: $grey-lightest;
    border-radius: 0.25rem;
    margin-bottom: 1.5rem;
  }
}
