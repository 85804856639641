@import "./Tab/tab";

.tabs {
  @include flex-justify-start;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 900px) {
  .tabs {
    margin-bottom: 1.5rem;
  }
}
