.diagnostics-upgrade-hint {
  text-align: center;
  padding: 5rem 0;
  width: 20rem;
  margin: 0 auto;
  @include flex-justify-center;

  .diagnostics-upgrade-hint-illustration {
    display: block;
    width: 100%;

    svg {
      margin: 0 auto 0.5rem;
      width: 11.25rem;
    }
  }

  .diagnostics-upgrade-hint-text {
    display: block;
    margin: 0.5rem 0 1rem 0;
    font-size: 0.875rem;
    line-height: 1.5rem;

    &:first-of-type {
      margin-bottom: 0;
    }
  }
}
