.StripeElement {
  border-radius: 0.5rem;
  border: 1px solid $grey-lighter;
  padding: 0.875rem 1rem;
}

.payment-form {
  position: relative;
  margin-top: 0.75rem;

  &.payment-form-loading {
    .payment-form-loader {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.payment-form-has-error {
    .payment-form-error {
      opacity: 1;
      pointer-events: all;
    }
  }

  .payment-form-loader {
    background-color: white;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms ease-out;
    will-change: opacity, pointer-events;

    &:before {
      @include animation-preset;
      display: block;
      content: "";
      border: $orange-medium calculate-rem(3px) solid;
      border-top: transparent calculate-rem(3px) solid;
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
      animation: loaderSpin 600ms forwards infinite;
      z-index: 2;
      pointer-events: none;
      opacity: 1;
    }
  }

  .payment-form-error {
    background-color: white;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    opacity: 0;
    will-change: opacity, pointer-events;

    .payment-form-error-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      .payment-form-error-icon {
        color: $red-medium;
        font-size: 1.5rem;
      }

      .payment-form-error-text {
        width: 15rem;
        font: 600 0.875rem/1.25rem $font;
        color: $grey-darkest;
        margin-top: 0.25rem;
      }
    }
  }
}
