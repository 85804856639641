.modal-small {
  padding: 1.5rem;

  .modal-small-header {
    .modal-small-header-title {
      display: block;
      margin-bottom: 0.5rem;
    }

    .modal-small-header-description {
      line-height: 1.5rem;
      font-size: 0.875rem;
    }
  }

  .modal-small-body {
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 720px) {
  .modal-small {
    max-width: 27.5rem;
    padding: 2rem;

    .modal-small-header {
      .modal-small-header-title {
        margin-bottom: 0.75rem;
      }
    }
    .modal-small-body {
      margin-top: 1.5rem;
    }
  }
}
