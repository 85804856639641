.button-with-icon {
  display: grid;
  gap: 0.375rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;

  &.button-loading {
    .button-with-icon-icon,
    .button-with-icon-text {
      visibility: hidden;
    }
  }

  .button-with-icon-text {
    line-height: 1.25rem;
    margin: 0.125rem 0;
  }

  &.button-secondary {
    padding-top: calculate-rem(9px);
    padding-bottom: calculate-rem(9px);

    .button-with-icon-icon {
      color: $grey-medium;
    }
  }

  &.button-with-icon-right {
    grid-template-columns: 1fr 1.5rem;
    padding-left: 1rem;
    padding-right: 0.75rem;

    .button-with-icon-text {
      order: 1;
    }

    .button-with-icon-icon {
      order: 2;
    }
  }

  &.button-with-icon-left {
    grid-template-columns: 1.5rem 1fr;
    padding-left: 0.75rem;
    padding-right: 1rem;

    .button-with-icon-text {
      order: 2;
    }

    .button-with-icon-icon {
      order: 1;
    }
  }

  .button-with-icon-icon {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 1024px) {
  .button-with-icon {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    &.button-secondary {
      padding-top: calculate-rem(11px);
      padding-bottom: calculate-rem(11px);
    }
  }
}
