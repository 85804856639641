.pass-code-decision {
  .pass-code-decision-buttons {
    padding: 0.75rem 0 1.5rem 0;

    .button {
      margin-bottom: 0.5rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
