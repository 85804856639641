.react-calendar {
  width: calculate-rem(276px);
  --column-width: 2.25rem;
  --gap-width: 0.25rem;
  border: none;

  .react-calendar__navigation {
    display: grid !important;
    grid-template-columns: 1fr var(--column-width) var(--column-width);
    gap: var(--gap-width);
    height: auto;
    margin-bottom: 0;

    .react-calendar__navigation__next2-button,
    .react-calendar__navigation__prev2-button {
      display: none;
    }

    .react-calendar__navigation__next-button,
    .react-calendar__navigation__prev-button {
      @include button-preset;
      width: var(--column-width);
      height: var(--column-width);
      background-color: white;
      will-change: background-color;
      font-size: 1.5rem;
      color: $grey-medium;
      border-radius: 50%;
      min-width: auto;

      .icon-chevron-left,
      .icon-chevron-right {
        display: block;
      }

      @media (hover: hover) {
        &:hover {
          background-color: $grey-lightest;
        }
      }

      &:focus {
        background-color: $grey-lightest;
      }
    }

    .react-calendar__navigation__prev-button {
      grid-column: 2;
      grid-row: 1;
    }
    .react-calendar__navigation__next-button {
      grid-column: 3;
      grid-row: 1;
    }

    .react-calendar__navigation__label {
      @include button-preset;
      text-align: left;
      grid-column: 1;
      grid-row: 1;
      pointer-events: none;
      font: 600 1rem/1.25rem $font;
      letter-spacing: 0.4px;
      color: $grey-darkest;
    }
  }

  .react-calendar__viewContainer {
    .react-calendar__month-view__weekdays {
      display: grid;
      grid-template-columns:
        var(--column-width) var(--column-width) var(--column-width)
        var(--column-width) var(--column-width) var(--column-width) var(--column-width);
      gap: var(--gap-width);

      .react-calendar__month-view__weekdays__weekday {
        text-align: center;
        max-width: none !important;
        padding: 0;

        abbr {
          display: block;
          font: 500 0.75rem/1.125rem $font;
          text-transform: uppercase;
          letter-spacing: 0.8px;
          text-decoration: none;
          color: $grey-dark;
          padding: 0.5rem 0 var(--gap-width);
          height: 2.125rem;
        }
      }
    }

    .react-calendar__month-view__days {
      position: relative;
      display: grid !important;
      grid-template-columns:
        var(--column-width) var(--column-width) var(--column-width)
        var(--column-width) var(--column-width) var(--column-width) var(--column-width);
      gap: var(--gap-width);

      .react-calendar__tile {
        @include button-preset;
        background-color: $orange-light;
        will-change: background-color;
        border-radius: 50%;
        padding: 0.5rem;
        max-width: none !important;

        abbr {
          text-align: center;
          font: 500 0.875rem/1.25rem $font;
          color: $orange-darker;
          display: block;
        }

        &.react-calendar__tile--active {
          background-color: $orange-medium;

          abbr {
            color: white;
          }
        }

        &:disabled {
          background-color: white;
          cursor: auto;

          abbr {
            color: $grey-medium;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 375px) {
  .react-calendar {
    width: calculate-rem(328px);
    --column-width: 2.5rem;
    --gap-width: 0.5rem;

    .react-calendar__viewContainer {
      .react-calendar__month-view__days {
        .react-calendar__tile {
          padding: 0.625rem;
        }
      }
    }
  }
}
