.choose-plan-modal {
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: auto;
  padding: 1rem 0;

  .choose-plan-modal-header {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
  }
}

@media only screen and (min-width: 1100px) {
  .choose-plan-modal {
    padding: 2rem 0;

    .choose-plan-modal-header {
      top: 1.25rem;
      right: 2rem;
    }
  }
}
