.bullets-icon {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
}

.bullets-icon-bullet {
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  gap: 0.5rem;

  .bullets-icon-bullet-icon {
    font-size: 1.5rem;
    display: block;

    &.icon-tick {
      color: $green-medium;
    }

    &.icon-alert-circle-outline {
      color: $red-dark;
    }

    &.icon-tick-circle-fill {
      color: $orange-medium;
    }

    &.icon-cross-circle-fill {
      color: $grey-light;
    }
  }

  .bullets-icon-bullet-text {
    font: 400 0.875rem/1.25rem $font;
    color: $grey-dark;
    margin: 0.125rem 0;
  }
}

@media only screen and (min-width: 1100px) {
  .bullets-icon {
    gap: 0.75rem;

    &.bullets-icon-large {
      gap: 1.25rem;
    }
  }

  .bullets-icon-bullet {
    &.bullets-icon-bullet-large {
      gap: 0.625rem;

      .bullets-icon-bullet-text {
        font: 400 1rem/1.5rem $font;
      }
    }
  }
}
