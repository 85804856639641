.result-type-cards {
  padding-bottom: 1rem;
  display: grid;
  gap: 1.25rem;

  .result-type-card {
    border: 1px solid $grey-lighter;
    border-radius: 0.5rem;
    padding: 0.75rem 1.5rem 1.5rem;
    text-align: center;

    .result-type-card-illustration {
      max-width: 11.25rem;
      margin: 0 auto 0.75rem;
    }

    .result-type-card-paragraph {
      font-size: 0.875rem;
      line-height: 1.5rem;
      margin: 0.25rem 0 0.75rem 0;
    }

    .result-type-card-buttons {
      margin-top: 0.75rem;

      .button {
        display: inline-block;
        margin: 0 auto;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .result-type-cards {
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;

    .result-type-card {
      padding: 1rem 2rem 2rem;

      .result-type-card-paragraph {
        margin-top: 0.375rem;
      }
    }
  }
}
