.wrapper-width {
  padding: 0 1.25rem;
  margin-left: auto;
  margin-right: auto;
}

.wrapper-height {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media only screen and (min-width: 700px) {
  .wrapper-width {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (min-width: 900px) {
  .wrapper-width {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media only screen and (min-width: 1344px) {
  .wrapper-width {
    max-width: 1248px;
    padding-left: 0;
    padding-right: 0;
  }
}
