.dropdown-option {
  @include button-preset;
  width: 100%;
  display: grid;
  grid-template-columns: 2.5rem 1fr;
  gap: 0.875rem;
  padding: 1rem 1.5rem 1rem 1rem;
  border-bottom: 1px solid $grey-lightest;
  background-color: transparent;
  will-change: background-color;

  &:focus {
    background-color: $grey-lightest;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $grey-lightest;
    }
  }

  &:first-of-type {
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
  }

  &:last-of-type {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-bottom: none;
  }

  .dropdown-option-graphic {
    background-color: $orange-light;
    border-radius: 50%;
    padding: 0.625rem;
    margin: calculate-rem(3px) 0;

    .dropdown-option-graphic-icon {
      display: block;
      font-size: 1.25rem;
      width: 1.25rem;
      height: 1.25rem;
      color: $orange-medium;
    }
  }

  .dropdown-option-text {
    text-align: left;

    .dropdown-option-name {
      display: block;
      font: 500 1rem/1.375rem $font;
      color: $grey-darkest;
      margin: 0.125rem 0;
    }

    .dropdown-option-guidance {
      display: block;
      font: 400 0.875rem/1.25rem $font;
      color: $grey-dark;
    }
  }
}
