.modal-image-container {
  @include flex-justify-center;
  padding-bottom: 4rem;

  .modal-image-container-image {
    width: calc((100% - 4rem) / 2);
    padding-bottom: calc((100% - 4rem) / 2);
    margin: 1rem 1rem 0 1rem;
    position: relative;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media only screen and (min-width: 720px) {
  .modal-image-container {
    .modal-image-container-image {
      margin: 1.25rem 1.25rem 0 1.25rem;
      width: calculate-rem(260px);
      height: calculate-rem(260px);
      padding-bottom: 0;
    }

    &.modal-image-container-wide {
      .modal-image-container-image {
        width: calc((100% - 5rem) / 2);
        height: auto;
      }
    }
  }
}
