.card-image {
  .card-image-shape {
    position: relative;
    background-color: $grey-lightest;
    width: 100%;

    &.card-image-shape-loading {
      padding-bottom: 132%; // Shape of standard iPhone photo in portrait

      &:before {
        @include animation-preset;
        display: block;
        content: "";
        border-radius: 50%;
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
        animation: loaderSpin 600ms forwards infinite;
        border: $grey-medium 0.125rem solid;
        border-top: transparent 0.125rem solid;
      }
    }
  }

  .card-image-shape-loading + img {
    opacity: 0;
  }

  img {
    width: 100%;
    opacity: 1;
    will-change: opacity;
    transition: opacity 200ms ease;
  }
}
