@import "./ReportingGroup/reporting-group";

.reporting-group-loader {
  margin-top: 0.75rem;
  background-color: white;
  border-radius: 0.5rem;
  width: 100%;
  height: 20rem;
}

@media only screen and (min-width: 900px) {
  .reporting-group-loader {
    margin-top: 1.5rem;
    height: 25.5rem;
  }
}
