.modal {
  background-color: white;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 999;
  overflow: auto;

  .modal-header {
    display: grid;
    grid-template-columns: 1fr 2.5rem;
    padding: 0.375rem 1rem 0.375rem 1.25rem;
    border-bottom: 1px solid $grey-lightest;
    height: calculate-rem(53px);

    .modal-header-title {
      margin: calculate-rem(7px) 0;
    }
  }

  form {
    height: 100%;
  }

  .modal-body {
    padding: 1.25rem 1.25rem 0 1.25rem;
    height: calc((100% - 130px)); // 100% - modal-header - modal-footer
    overflow-y: auto;

    .modal-body-illustration {
      width: 12.5rem;
      margin: 0 auto 0.75rem;
    }
  }

  .modal-footer {
    border-top: 1px solid $grey-lightest;
    padding: 1rem 1.25rem;
    @include flex-justify-center;
    height: calculate-rem(77px);
    background-color: white;

    &.modal-footer-back-button {
      @include flex-space-between;
    }
  }
}

.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($grey-darkest, 0.2);
  z-index: 998;
}

@media only screen and (min-width: 800px) {
  .modal {
    width: 47.5rem;
    border-radius: 0.75rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    max-height: 100%;
    overflow: auto;

    &.modal-medium {
      width: calculate-rem(488px);
    }

    .modal-header {
      padding: 0.625rem 1.25rem 0.625rem 1.5rem;
      height: auto;
    }

    .modal-body {
      padding: 1.25rem 1.5rem 0.5rem 1.5rem;
      height: auto;
      overflow: visible;
    }

    .modal-footer {
      &.modal-footer-back-button {
        display: grid;
        grid-template-columns: calculate-rem(101px) 1fr calculate-rem(101px);

        .modal-footer-back-button-contents {
          @include flex-justify-center;
        }
      }

      height: auto;
    }
  }
}

@import "./WarningModal/warning-modal";
@import "./ModalSmall/modal-small";
@import "./Dialog/dialog";
@import "./ModalImageContainer/modal-image-container";
