.list-cards {
  background-color: white;
  border-radius: 0.5rem;

  .list-card {
    display: grid;
    grid-template-columns: 1.5rem 1fr;
    gap: 1rem;
    border-bottom: 1px solid $grey-lighter;
    padding: 0.75rem 1rem;

    &:last-of-type {
      border-bottom: none;
    }

    .list-card-result-icon {
      margin-top: 1.5rem;
      display: block;
      font-size: 1.5rem;

      &.icon-tick-circle-fill {
        color: $green-medium;
      }

      &.icon-alert-circle-fill {
        color: $red-dark;
      }

      &.icon-clock {
        color: $grey-medium;
      }
    }

    .list-card-data {
      display: block;
      margin-bottom: 0.375rem;
      font: 400 0.875rem/1.25rem $font;
      color: $grey-dark;

      &.list-card-data-bold {
        font-weight: 600;
        color: $grey-darkest;
      }
    }
  }
}

@media only screen and (min-width: 500px) {
  .list-cards {
    display: none;
  }
}
