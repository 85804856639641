@import "./Report/report";

.reporting-group {
  margin-top: 0.75rem;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem 1.25rem;
  display: grid;
  gap: 0.75rem;

  .reporting-group-details-heading {
    margin-bottom: 0.5rem;
  }

  .reporting-group-past-reporting {
    margin-top: 0.75rem;

    .reporting-group-past-reports {
      @include animation-preset;
      overflow: hidden;
      max-height: 0;
      will-change: max-height;

      &.reporting-group-past-reports-active {
        max-height: 1000px;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .reporting-group {
    margin-top: 1.5rem;
    gap: 1.5rem;
    grid-template-columns: 2fr 1fr;
    padding: 1.25rem 1.5rem;

    .reporting-group-details-heading {
      margin-bottom: 1rem;
    }

    .reporting-group-past-reporting {
      margin-top: 1rem;
    }

    .reporting-group-actions {
      @include flex-justify-end;
    }
  }
}
