.card-data {
  display: grid;
  gap: 0.5rem;

  .card-data-label {
    font: 500 0.875rem/1.25rem $font;
    color: $grey-dark;
  }

  .card-data-body {
    font: 400 0.875rem/1.25rem $font;
    color: $grey-dark;
  }
}

@media only screen and (min-width: 1100px) {
  .card-columns-3 {
    .card-data-span-3 {
      grid-column: 1/3;
    }
  }
}

@media only screen and (min-width: 1350px) {
  .card-columns-3 {
    .card-data-span-3 {
      grid-column: 1/4;
    }
  }
}
