.nav {
  position: sticky;
  top: 0;
  right: 0;
  width: 100%;
  background-color: white;
  padding: 0.25rem 1rem 0.25rem 0.75rem;
  display: grid;
  grid-template-columns: 2.5rem 1fr 2.5rem 3.75rem;
  gap: 0.5rem;
  align-items: center;
  z-index: 996;

  .nav-logo {
    margin: 0.25rem 0;

    svg {
      height: 2rem;
    }
  }

  .nav-item {
    .nav-tablet {
      display: none;
    }

    .nav-link-group {
      @include animation-preset;
      background-color: $grey-darker;
      border-radius: 0.5rem;
      position: absolute;
      right: 0.75rem;
      width: calc(100% - 1.5rem);
      pointer-events: none;
      opacity: 0;
      will-change: opacity;

      &.active {
        opacity: 1;
        pointer-events: all;
      }

      .nav-link-group-section {
        padding: 0.5rem;
        border-top: rgba(white, 0.1) solid 1px;

        &:first-of-type {
          border-top: none;
        }

        .nav-link {
          a,
          button {
            @include button-preset;
            font: 600 0.875rem/1.25rem $font;
            color: $grey-medium;
            display: block;
            width: 100%;
            text-align: left;
            letter-spacing: 0.4px;
            padding: 0.625rem 0.75rem;
            border-radius: 0.25rem;

            &:focus {
              background-color: rgba($grey-darkest, 0.48);
            }

            @media (hover: hover) {
              &:hover {
                background-color: rgba($grey-darkest, 0.48);
              }
            }
          }
        }

        .nav-log-out-button {
          @include button-preset;
          font: 600 0.875rem/1.25rem $font;
          color: $grey-medium;
          padding: 0.5rem 0.75rem;
          display: grid;
          width: 100%;
          grid-template-columns: 1.5rem 1fr;
          gap: 0.5rem;
          text-align: left;
          align-items: center;
          border-radius: 0.25rem;

          &:focus {
            background-color: rgba($grey-darkest, 0.48);
          }

          @media (hover: hover) {
            &:hover {
              background-color: rgba($grey-darkest, 0.48);
            }
          }

          .icon-arrow-right {
            font-size: 1.5rem;
          }
        }
      }
    }

    .nav-account-button {
      @include button-preset;
      display: grid;
      grid-template-columns: 2rem 1.5rem;
      align-items: center;
      padding: 0.25rem 0 0.25rem 0.25rem;
      border-radius: 0.25rem;

      @media (hover: hover) {
        &:hover {
          background-color: $grey-lightest;
        }
      }

      &.active {
        background-color: $grey-lightest;

        .nav-account-button-icon {
          transform: rotate(180deg);
        }
      }

      .nav-account-button-initials {
        display: block;
        background-color: $green-light;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        position: relative;

        .nav-account-button-initials-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font: 500 1rem/1.5rem $font;
          letter-spacing: 0.4px;
          display: block;
        }
      }

      .nav-account-button-icon {
        font-size: 1.5rem;
        color: $grey-medium;
        transform: rotate(0deg);
        transition: 150ms transform ease;
        will-change: transform;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .nav {
    grid-template-columns: 2.5rem 7.5rem 1fr 3.75rem;

    .nav-mobile {
      display: none;
    }

    .nav-item {
      position: relative;

      .nav-tablet {
        @include flex-justify-end;
        text-align: right;

        .button-fit-for-work-status {
          margin-right: 0.75rem;
        }

        .nav-tablet-link {
          font: 500 0.875rem/1.25rem $font;
          color: $grey-dark;
          text-decoration: none;
          padding: 0.625rem 0.5rem;

          @media (hover: hover) {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .nav-link-group {
        width: 15rem;
        right: 0;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .nav {
    width: calc(100% - 15rem);
    right: 0;
    left: 15rem;
    grid-template-columns: 1fr 4rem;
    padding: 0.5rem 1.5rem;

    .nav-logo {
      display: none;
    }

    .nav-mobile-menu {
      display: none;
    }

    .nav-item {
      .nav-tablet {
        .nav-tablet-link {
          margin: 0 0.25rem;
        }
      }

      .nav-account-button {
        margin-left: 0.25rem;
      }
    }
  }
}
