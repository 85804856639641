.plan-header {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1.5rem;

  &.plan-header-manage-subscription,
  &.plan-header-sign-up {
    .plan-header-title {
      font-size: 1rem;
    }

    .plan-header-description {
      font-size: 0.875rem;
      line-height: 1.5rem;
    }
  }

  .plan-header-description {
    margin: 0.5rem 0 1rem;
  }

  .plan-header-price {
    .plan-header-price-value {
      font: 600 2rem/2.75rem $font;
      color: $grey-darkest;
      letter-spacing: 0;
      margin-right: 0.125rem;

      &.plan-header-price-value-discounted {
        text-decoration: line-through;
      }
      &.plan-header-price-value-discount {
        color: $red-dark;
      }
    }

    .plan-header-price-period {
      color: $grey-dark;
      letter-spacing: 0;
    }
  }
}

@media only screen and (min-width: 1100px) {
  .plan-header {
    .plan-header-description {
      margin-bottom: 1.25rem;
    }

    &.plan-header-manage-subscription,
    &.plan-header-sign-up {
      .plan-header-description {
        margin-bottom: 1rem;
      }
    }
  }
}
