.report-condition {
  border: 1px solid $grey-lighter;
  border-radius: 0.5rem;
  padding: 0.75rem;
  margin-bottom: 0.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  .report-condition-body {
    margin-top: 0.5rem;

    .button {
      display: inline-block;
      margin-bottom: 0.5rem;

      @supports (width: max-content) {
        width: max-content;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .report-condition {
    .report-condition-body {
      margin-top: 0.75rem;
    }
  }
}
