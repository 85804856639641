@import "./AppointmentCard/appointment-card";

.view-result {
  .view-result-header {
    margin-top: 0.5rem;

    .view-result-header-info {
      .button {
        margin-top: 0.25rem;
      }
    }

    .view-result-header-data {
      .tag,
      .verified-result {
        margin-top: 0.5rem;
      }
    }
  }

  .view-result-body {
    margin-top: 1rem;
    display: grid;
    gap: 1rem;

    .view-result-body-test {
      display: grid;
      gap: 1rem;
      order: 2;
    }
  }
}

.view-result-loader {
  min-height: calculate-rem(335px);

  &.query-error {
    padding: 5rem 1.25rem;
  }
}

.result-reading {
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  align-items: center;
  gap: 0.5rem;

  .result-reading-icon {
    font-size: 1.5rem;
    display: block;

    &.result-reading-icon-positive {
      color: $green-medium;
    }

    &.result-reading-icon-negative {
      color: $red-dark;
    }

    &.result-reading-icon-pending {
      color: $grey-medium;
    }
  }

  .result-reading-text {
    color: $grey-darkest;
    font-weight: 600;
  }
}

@media only screen and (min-width: 720px) {
  .view-result {
    .view-result-body {
      @include flex-space-between;
      align-items: flex-start;

      .view-result-body-test {
        order: 1;
        width: calc(100% - 23.25rem);
        gap: 1.25rem;
      }

      .view-result-body-result {
        width: 22rem;
        order: 2;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .view-result {
    .view-result-header {
      align-items: center;

      .view-result-header-info {
        @include flex-space-between;
        align-items: center;

        .button {
          margin-top: 0;
        }
      }

      .view-result-header-data {
        @include flex-justify-start;
        margin-top: 0.25rem;

        .tag,
        .verified-result {
          margin-top: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 1100px) {
  .view-result-loader {
    min-height: calculate-rem(500px);

    &.query-error {
      padding: 10rem 1.5rem;
    }
  }
}
