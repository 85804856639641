@import "./Nav/nav";
@import "./Sidebar/sidebar";

.layout {
  padding: 1rem 1.25rem 1.5rem;
  position: relative;
}

@media only screen and (min-width: 900px) {
  .layout {
    margin-left: 15rem;
    padding: 1.25rem 2rem;
  }
}
