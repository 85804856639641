.sidebar-item {
  background-color: transparent;
  border-radius: 0.25rem;
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  gap: 0.75rem;
  align-items: center;
  padding: 0.75rem 1rem;

  &.active {
    background-color: rgba($grey-darkest, 0.48);

    .sidebar-item-icon {
      color: $orange-medium;
    }

    .sidebar-item-name {
      color: white;
    }
  }

  &:focus {
    background-color: rgba($grey-darkest, 0.48);
  }

  @media (hover: hover) {
    &:hover {
      background-color: rgba($grey-darkest, 0.48);
    }
  }

  .sidebar-item-icon {
    display: block;
    color: $grey-medium;
    font-size: 1.5rem;
  }

  .sidebar-item-name {
    display: block;
    font: 600 1rem/1.5rem $font;
    color: $grey-medium;
  }
}

@media only screen and (min-width: 900px) {
  .sidebar-item {
    gap: 0.625rem;
    padding: 0.5rem 1.5rem;

    .sidebar-item-name {
      text-align: left;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}
