@import "./Slot/slot";

.scheduler {
  position: relative;

  &.scheduler-loading {
    .scheduler-loader {
      opacity: 1;
      pointer-events: all;
    }
  }

  .scheduler-loader {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: calculate-rem(74px);
    background-color: white;
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms ease-out;

    &:before {
      @include animation-preset;
      display: block;
      content: "";
      border: $orange-medium calculate-rem(3px) solid;
      border-top: transparent calculate-rem(3px) solid;
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
      animation: loaderSpin 600ms forwards infinite;
      z-index: 2;
      pointer-events: none;
      opacity: 1;
    }
  }
}

.scheduler-slots {
  max-height: 22rem;

  &.scheduler-slots-error {
    .scheduler-slots-placeholder {
      border-color: $red-medium;
    }

    .slots {
      border-color: $red-medium;
    }
  }

  &.scheduler-slots-loading {
    .slots {
      .scheduler-slots-loader {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .scheduler-slots-heading {
    font: 600 1rem/1.25rem $font;
    color: $grey-darkest;
    display: block;
    padding: 0.625rem 0;
    height: 2.5rem;
  }

  .scheduler-slots-placeholder {
    position: relative;
    height: 19.5rem;
    border-radius: 0.5rem;
    border: 1px solid $grey-lightest;

    .scheduler-slots-placeholder-text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 100%;
      text-align: center;
      font: 500 0.875rem/1.25rem $font;
      color: $grey-dark;
      padding: 2rem;
    }
  }

  .slots {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    height: 19.5rem;
    overflow-y: auto;
    border-radius: 0.5rem;
    border: 1px solid $grey-lightest;
    padding: 0.5rem;
    position: relative;

    .scheduler-slots-loader {
      background-color: white;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      pointer-events: none;
      opacity: 0;
      transition: opacity 300ms ease-out;

      &:before {
        @include animation-preset;
        display: block;
        content: "";
        border: $orange-medium calculate-rem(3px) solid;
        border-top: transparent calculate-rem(3px) solid;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
        animation: loaderSpin 600ms forwards infinite;
        z-index: 2;
        pointer-events: none;
        opacity: 1;
      }
    }
  }
}

.scheduler-error {
  padding: 0.75rem 0 0.5rem;
}

@media screen and (min-width: 720px) {
  .scheduler-error {
    padding-bottom: 0;
  }
}
