.dialog {
  text-align: center;
  padding: 1.5rem;

  .tag {
    margin-bottom: 1rem;
  }

  .dialog-icon {
    font-size: 1.5rem;

    &.icon-tick-circle-fill {
      color: $green-medium;
    }
    &.icon-alert-circle-outline {
      color: $red-medium;
    }
  }

  .dialog-heading {
    margin: 0.25rem 0 0.75rem 0;

    &.dialog-heading-with-description {
      margin-bottom: 0.5rem;
    }
  }

  .dialog-description {
    font: 400 0.875rem/1.5rem $font;
    margin-bottom: 1rem;
  }
}

.dialog-footer {
  .input-select-result {
    @supports (width: max-content) {
      margin: 0 auto;
      width: max-content;
    }

    .label {
      justify-content: center;
    }
  }

  .button {
    margin: 0 auto 0.5rem;

    @supports (width: max-content) {
      width: max-content;
    }
  }
}
