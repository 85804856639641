.input-time-picker {
  .react-time-picker {
    width: 100%;
    display: block;

    .react-time-picker__wrapper {
      appearance: none;
      border: 1px solid $grey-lighter;
      border-radius: 0.25rem;
      font: 400 1rem/1.5rem $font;
      letter-spacing: 0.4px;
      color: $grey-darkest;
      padding: calculate-rem(11px) 0.875rem;

      .react-time-picker__inputGroup {
        height: 1.5rem;

        .react-time-picker__inputGroup__divider,
        .react-time-picker__inputGroup__leadingZero {
          display: inline-block;
          vertical-align: baseline;
        }

        .react-time-picker__inputGroup__input {
          line-height: 1.5rem;
        }
      }
    }
  }

  .react-time-picker__clock {
    border-radius: 0.5rem;
    border: 1px solid $grey-lighter;
  }
}
