.filter {
  .input-select {
    .react-select__control {
      height: 42px;

      .react-select__value-container {
        padding: calculate-rem(2px) 0.75rem;

        .react-select__single-value,
        .react-select__placeholder {
          font-size: 0.875rem;
          font-weight: 500;
          height: 26px;
          width: 100%;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
