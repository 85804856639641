@import "./AppointmentList/NewAppointmentModal/Step2/scheduler";
@import "./AppointmentList/AppointmentReview/appointment-review";
@import "./AppointmentList/NewAppointmentModal/Step3/payment-form";

.calendar {
  background-color: white;
  padding: 0.75rem 0.5rem;
  border-radius: 0.5rem;
  position: relative;

  .calendar-loader {
    background-color: white;
    position: absolute;
    left: 0;
    top: calculate-rem(52px); // 40px + 12px padding
    bottom: 0;
    right: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms ease-out;
    z-index: 4;

    &:before {
      @include animation-preset;
      display: block;
      content: "";
      border: $orange-medium calculate-rem(3px) solid;
      border-top: transparent calculate-rem(3px) solid;
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      left: 50%;
      top: 10rem;
      transform: translate3d(-50%, 0, 0) rotate(45deg);
      animation: loaderSpin 600ms forwards infinite;
      z-index: 2;
      pointer-events: none;
      opacity: 1;
    }
  }

  &.calendar-loading {
    .calendar-loader {
      opacity: 1;
      pointer-events: all;
    }
  }

  .rbc-calendar {
    .rbc-toolbar {
      justify-content: flex-start;

      .calendar-toolbar-controls {
        margin-left: 0.75rem;
        display: inline-block;

        .calendar-toolbar-icon-button {
          @include button-preset;
          padding: 0.5rem;
          border-radius: 50%;
          cursor: pointer;
          background-color: transparent;

          &.rbc-active,
          &:active {
            box-shadow: none;
            background-color: $grey-lightest;
          }

          .calendar-toolbar-icon-button-icon {
            display: block;
            font-size: 1.5rem;
            color: $grey-medium;
          }

          &:hover {
            background-color: $grey-lightest;

            .calendar-toolbar-icon-button-icon {
              color: $grey-dark;
            }
          }
        }
      }

      .calendar-toolbar-text-button {
        @include button-preset;
        font: 500 0.875rem/1.25rem $font;
        padding: calculate-rem(9px) 0.75rem;
        border-radius: 0.5rem;
        border: 1px solid $grey-lighter;
        color: $grey-dark;
        cursor: pointer;

        &.rbc-active,
        &:active {
          box-shadow: none;
        }

        &:hover {
          // overriding react-big-calendar styles
          color: $grey-dark;
          border: 1px solid $grey-lightest;
          background-color: $grey-lightest;
        }
      }

      .rbc-toolbar-label {
        padding-left: 0.5rem;
        font: 600 1rem/1.625rem $font;
        color: $grey-darkest;
        text-align: left;
      }
    }

    .rbc-time-view {
      border-bottom: none;
      border-color: $grey-lighter;

      .rbc-time-header {
        border-right: none;
        .rbc-time-header-content {
          border-color: $grey-lighter;

          .rbc-header {
            border-bottom: none;
            font: 500 0.875rem/1.25rem $font;
            color: $grey-dark;
            border-color: $grey-lighter;
          }

          .rbc-allday-cell {
            display: none;
          }
        }
      }

      .rbc-timeslot-group {
        border-color: $grey-lighter;

        .rbc-time-slot {
          &:first-of-type {
            border-top: none;
          }
        }
      }

      .rbc-events-container {
        border-color: $grey-lighter;

        .rbc-event {
          background-color: $orange-medium;
          @include flex-justify-start;
          overflow: hidden;
          border: none;

          .rbc-event-label {
            width: 100%;
            font: 500 0.75rem/1rem $font;
            overflow: visible;
          }

          .rbc-event-content {
            width: 100%;
            font: 600 0.875rem/1rem $font;
          }
        }
      }

      .rbc-time-content {
        border-top-width: 1px;
        border-color: $grey-lighter;

        .rbc-time-gutter {
          font: 500 0.75rem/0.875rem $font;
          color: $grey-dark;
        }
      }
    }
  }
}

@media only screen and (min-width: 720px) {
  .calendar {
    padding: 1.25rem 1rem;

    .calendar-loader {
      top: calculate-rem(60px);
    }

    .rbc-calendar {
      .rbc-toolbar {
        .rbc-toolbar-label {
          padding-left: 0.75rem;
          font-size: 1.125rem;
          line-height: 1.625rem;
        }
      }

      .rbc-time-view {
        .rbc-time-content {
          .rbc-time-gutter {
            font-size: 0.875rem;
            line-height: 1.25rem;
          }
        }
      }
    }
  }
}
