.query {
  position: relative;
  width: 100%;
}

.query-error {
  text-align: center;
  padding: 1.25rem;

  .query-error-icon {
    color: $red-dark;
    font-size: 1.5rem;
  }

  .query-error-message {
    margin-top: 0.5rem;
    font: 600 0.875rem/1.25rem $font;
    color: $grey-darkest;
    padding-bottom: 0.5rem;
  }
}

@media only screen and (min-width: 1100px) {
  .query-error {
    padding: 1.5rem;

    .query-error-message {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}
