.nav-subscription {
  grid-template-columns: 1fr 4rem;
  padding: 0.25rem 1rem 0.25rem 1.25rem;

  .nav-logo {
    display: none;
  }

  .nav-subscription-back-button {
    @include flex-justify-start;
  }
}

@media only screen and (min-width: 900px) {
  .nav-subscription {
    left: 0;
    width: 100%;
    grid-template-columns: 10rem 1fr 4rem;
    gap: 1rem;
    padding: 0.5rem 1.5rem;

    .nav-logo {
      display: block;

      svg {
        height: 2.5rem;
      }
    }

    .nav-subscription-back-button {
      justify-content: flex-end;
    }
  }
}
