@import "./UploadedFile/uploaded-file";

.image-uploader-position {
  max-width: 25rem;

  .image-uploader {
    margin-bottom: 0.5rem;
    width: 100%;
    padding-bottom: 75%;
    position: relative;
    background-color: #f9f9fa;

    &.image-uploader-vaccinations {
      .image-uploader-illustration {
        max-width: 8rem;
        margin: 0 auto 0.75rem;
      }
    }

    &.image-uploader-self-test {
      .image-uploader-illustration {
        max-width: 16.25rem;
        margin: 0 auto 1rem;
      }
    }

    .image-uploader-button {
      @include button-preset;
      display: block;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-height: 100%;

      .image-uploader-button-text {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }

      .image-uploader-button-helper-text {
        display: block;
        font: 400 0.875rem/1.25rem $font;
        color: $grey-medium;
        margin: 1rem 0 0 0;
      }

      .button {
        margin: 0 auto;
      }

      .image-uploader-pdf {
        margin-top: 0.5rem;

        .image-uploader-pdf-input {
          display: none;
        }
      }
    }

    .image-uploader-desktop {
      display: none;
    }

    .image-uploader-photo {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }

      .image-uploader-photo-replace-button {
        position: absolute;
        font-size: 0.875rem;
        line-height: 1.25rem;
        left: 1rem;
        bottom: 1rem;
      }

      .image-uploader-photo-remove-button {
        position: absolute;
        font-size: 0.875rem;
        line-height: 1.25rem;
        right: 1rem;
        bottom: 1rem;
      }
    }
  }
}

.image-uploader-files {
  margin-top: 1rem;
}

.image-uploader-error {
  margin-bottom: 0.5rem !important;
}

.image-uploader-example {
  margin: 0 auto;
  padding-bottom: 1.5rem;
}

@media only screen and (min-width: 720px) {
  .image-uploader-example {
    width: 100%;
  }
}

@media only screen and (min-width: 1100px) {
  .image-uploader-position {
    .image-uploader {
      &.image-uploader-vaccinations {
        .image-uploader-illustration {
          max-width: 10rem;
          margin: 0 auto 1rem;
        }
      }

      .image-uploader-desktop {
        display: inline;
      }
      .image-uploader-mobile {
        display: none;
      }
    }
  }
}
