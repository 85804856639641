.list-page-header {
  @include flex-space-between;
  align-items: center;
  margin-bottom: 0.75rem;
  position: relative;

  &.list-page-header-diagnostics {
    margin-bottom: 0;
  }

  .list-page-header-tag {
    @include flex-justify-start;
    align-items: center;

    .list-page-header-tag-heading {
      margin-right: 0.5rem;
    }
  }

  .list-page-header-dropdowns {
    @include flex-justify-start;
    margin-top: 0.5rem;
    width: 100%;
    position: relative;

    .list-page-header-dropdown {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;

      @media only screen and (min-width: 500px) {
        width: 100%;
        position: relative;
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }
}

.list-page-table {
  display: none;
}

.list-page-loader {
  background-color: white;
  width: 100%;
  border-radius: 0.5rem;
  min-height: calculate-rem(102px);
}

.list-page-empty {
  text-align: center;
  background-color: white;
  width: 100%;
  border-radius: 0.5rem;
  padding: 1.25rem;

  .list-page-empty-description {
    margin: 0 0 0.75rem 0;
  }

  .button {
    margin: 0 auto;
  }
}

@media only screen and (min-width: 500px) {
  .list-page-loader {
    min-height: calculate-rem(89px);
  }

  .list-page-table {
    display: table;
    background-color: white;
    width: 100%;
    table-layout: auto;
    border-radius: 0.5rem;
    text-align: left;
    font: 400 0.875rem/1.25rem $font;
    color: $grey-dark;

    .list-page-table-header {
      font-weight: 500;

      .list-page-table-header-cell {
        padding: 0.5rem 0.75rem 0.375rem;
        border-bottom: 0.125rem solid transparent;

        &.list-page-table-header-cell-desktop {
          display: none;

          @media only screen and (min-width: 1200px) {
            display: table-cell;
          }
        }

        &.list-page-table-header-cell-sorted {
          border-bottom: 0.125rem solid $orange-medium;

          .list-page-table-header-cell-text {
            color: $grey-darkest;
          }
        }

        .list-page-table-header-cell-layout {
          @include flex-justify-start;

          .list-page-table-header-cell-text {
            display: block;
            margin: 0.125rem 0;
            max-width: calc(100% - 1.625rem);

            @media only screen and (min-width: 1378px) {
              max-width: none;
            }
          }

          .list-page-table-header-cell-icon {
            font-size: 1.5rem;
            color: $grey-medium;
          }
        }

        @media (hover: hover) {
          &:hover {
            background-color: $grey-lightest;
          }
        }

        &:first-of-type {
          padding-left: 1rem;
        }

        &:last-of-type {
          padding-right: 1rem;
        }
      }
    }

    .list-page-table-body {
      .list-page-table-body-row {
        cursor: pointer;

        @media (hover: hover) {
          &:hover {
            background-color: $grey-lightest;
          }
        }

        .list-page-table-body-cell {
          border-top: 1px solid $grey-lighter;
          padding: 0.75rem;
          vertical-align: top;

          &:first-of-type {
            padding-left: 1rem;
          }

          &:last-of-type {
            padding-right: 1rem;
          }

          &.list-page-table-body-cell-desktop {
            display: none;

            @media only screen and (min-width: 1200px) {
              display: table-cell;
            }
          }

          .list-page-table-body-cell-final-column {
            display: grid;
            grid-template-columns: 1fr 1.5rem;

            .list-page-table-body-cell-final-column-icon {
              font-size: 1.5rem;
              color: $grey-light;
            }
          }

          .list-page-table-body-cell-text {
            display: block;
            line-height: 1.25rem;
            margin: 0.125rem 0;
          }

          .list-page-table-body-cell-bold {
            font-weight: 600;
            color: $grey-darkest;
          }

          .list-page-table-body-cell-reading {
            display: grid;
            grid-template-columns: 1.5rem 4.5rem 1.5rem;
            align-items: center;
            gap: 0.5rem;

            .list-page-table-body-cell-reading-icon {
              font-size: 1.5rem;

              &.icon-tick-circle-fill {
                color: $green-medium;
              }

              &.icon-alert-circle-fill {
                color: $red-dark;
              }

              &.icon-clock {
                color: $grey-medium;
              }
            }

            .list-page-table-body-cell-reading-chevron {
              font-size: 1.5rem;
              color: $grey-light;
            }
          }
        }
      }
    }
  }

  .list-page-header {
    .list-page-header-dropdowns {
      margin: 0;
      width: auto;

      .list-page-header-dropdown {
        width: auto;
        position: relative;
      }
    }
  }
}

@media only screen and (min-width: 720px) {
  .list-page-header {
    &.list-page-header-diagnostics {
      margin-bottom: 0.75rem;
    }

    .list-page-header-tag {
      .list-page-header-tag-heading {
        margin-right: 0.75rem;
      }
    }

    .list-page-header-dropdowns {
      .list-page-header-dropdown {
        margin-right: 0.75rem;
      }
    }
  }
}

@media only screen and (min-width: 1100px) {
  .list-page-empty {
    padding: 1.5rem;
  }
}
