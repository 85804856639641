.change-plan {
  background-color: white;
  min-height: 100vh;
}

.plans-info {
  p {
    margin-top: 0.5rem;

    a {
      text-decoration: underline;
      color: $grey-dark;

      @media (hover: hover) {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.plans {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
  padding: 1.5rem 0;
}

@media only screen and (min-width: 1100px) {
  .plans-info {
    text-align: center;
    margin-top: 0.75rem;

    p {
      margin-top: 0.75rem;
    }
  }

  .plans {
    padding: 2.5rem 0;
    margin: 0 auto;
    gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
}
