.date-range {
  appearance: none;
  display: grid;
  grid-template-columns: 1fr 0.5rem 1fr;
  align-items: center;
  border-radius: 0.25rem;
  border: 1px solid $grey-lighter;
  position: relative;

  .date-range-divider {
    color: $grey-medium;
  }

  .react-date-picker {
    .react-date-picker__wrapper {
      border: none;
      appearance: none;
      border-radius: 0.25rem;
      font: 500 0.875rem/1.5rem $font;
      letter-spacing: 0.4px;
      color: $grey-darkest;
      padding: 0.5rem 0.75rem;

      .react-date-picker__inputGroup {
        .react-date-picker__inputGroup__input {
          line-height: 1.5rem;
          height: 100%;

          &:placeholder-shown,
          &::placeholder {
            color: $grey-medium;
          }

          &:placeholder-shown + .react-date-picker__inputGroup__divider {
            color: $grey-medium;
          }
        }
        .react-date-picker__inputGroup__divider {
          line-height: 1.5rem;
          height: 100%;
        }
      }
    }

    .react-date-picker__calendar {
      width: calculate-rem(304px);

      @media only screen and (min-width: 400px) {
        width: calculate-rem(356px) !important;
      }

      .react-calendar {
        border-radius: 0.5rem;
        padding: 0.25rem 0.875rem 0.75rem 0.875rem;
        border: 1px solid $grey-lighter;
        width: calculate-rem(304px);

        @media only screen and (min-width: 375px) {
          --column-width: 2.25rem;
          --gap-width: 0.25rem;
        }

        @media only screen and (min-width: 400px) {
          width: calculate-rem(356px);
          --column-width: 2.5rem;
          --gap-width: 0.5rem;
        }

        .react-calendar__navigation {
          margin-bottom: 0;
        }

        .react-calendar__viewContainer {
          .react-calendar__month-view__days {
            .react-calendar__tile {
              @media only screen and (min-width: 375px) {
                padding: 0.5rem;
              }

              @media only screen and (min-width: 400px) {
                padding: 0.625rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 720px) {
  .date-range {
    @include flex-justify-start;
  }
}
