.billing-info-back-button {
  width: 11rem;

  @supports (width: max-content) {
    width: max-content;
  }
}

.billing-info {
  display: grid;
  gap: 1.5rem;
  margin-top: 1.5rem;

  .billing-info-details {
    order: 2;
  }

  .billing-info-plan {
    order: 1;

    .billing-info-plan-details {
      border: 1px solid $grey-lighter;
      border-radius: 0.5rem;
      padding: 1rem 1.25rem 1.25rem;
    }
  }
}

.change-plan-guidance {
  .change-plan-guidance-icon {
    color: $green-medium;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    display: block;

    &.icon-alert-circle-outline {
      color: $red-medium;
    }
  }

  .change-plan-guidance-text {
    max-width: 31rem;
    margin: 0.5rem 0 1rem 0;
  }

  .change-plan-guidance-list {
    margin-bottom: 1.25rem;
  }

  .button {
    display: inline-block;
  }
}

@media only screen and (min-width: 1100px) {
  .billing-info {
    grid-template-columns: 2fr 1fr;

    .billing-info-details {
      order: 1;
    }

    .billing-info-plan {
      order: 2;

      .billing-info-plan-details {
        padding: 1.25rem 1.5rem 1.5rem;
      }
    }
  }

  .change-plan-guidance {
    .change-plan-guidance-icon {
      font-size: 1.75rem;
      margin-bottom: 0.75rem;
    }

    .change-plan-guidance-text {
      margin-top: 0.75rem;
      margin-bottom: 1.25rem;
    }

    .change-plan-guidance-list {
      margin-bottom: 1.5rem;
    }
  }
}
