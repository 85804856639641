@import "./DateRange/date-range";

.result-filters {
  position: relative;
  margin-bottom: 0.75rem;

  &.active {
    .result-filter-button {
      .button {
        &:before {
          opacity: 1;
        }
      }
    }

    .result-filter-options {
      padding-bottom: 0.5rem;
    }
  }

  .result-filter-button {
    position: relative;

    .button {
      &:before {
        content: "";
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        background-color: $orange-medium;
        display: block;
        position: absolute;
        right: -0.25rem;
        top: -0.25rem;
        pointer-events: none;
        opacity: 0;
      }
    }
  }

  .result-filter-options {
    position: absolute;
    width: 100%;
    background-color: white;
    border-radius: 0.5rem;
    padding: 1rem 1rem 0.25rem 1rem;
    pointer-events: none;
    opacity: 0;
    border: 1px solid $grey-lightest;
    box-shadow: 0 0.125rem 0.5rem rgba($grey-darkest, 0.06);
    z-index: 995;

    .filter,
    .date-range {
      margin-bottom: 0.75rem;

      .input-error-text {
        display: none;
      }
    }

    &.active {
      pointer-events: all;
      opacity: 1;
    }
  }
}

@media only screen and (min-width: 720px) {
  .result-filters {
    .result-filter-button {
      display: none;
    }

    .result-filter-options {
      position: static;
      opacity: 1;
      box-shadow: none;
      pointer-events: all;
      padding: 0.75rem;
      @include flex-justify-start;

      .filter {
        width: 14rem;
        margin: 0 0.75rem 0 0;
      }

      .date-range {
        margin: 0 0.75rem 0 0;
      }

      .button {
        width: 9rem;
        margin: 0;
        font-size: 0.875rem;
        padding: calculate-rem(7px) 0.875rem;

        @supports (width: max-content) {
          width: max-content;
        }
      }
    }
  }
}
