.uploaded-file {
  border: 1px solid $grey-lighter;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  display: grid;
  grid-template-columns: 1.5rem 1fr 2.5rem;
  gap: 0.5rem;

  .uploaded-file-icon {
    display: block;
    font-size: 1.5rem;
    color: $orange-medium;
    margin: 0.5rem 0;
  }

  .uploaded-file-data {
    font: 400 1rem/1.5rem $font;
    margin: 0.5rem 0;

    .uploaded-file-data-name {
      color: $grey-darkest;
      display: inline-block;
      margin-right: 0.25rem;
    }

    .uploaded-file-data-size {
      color: $grey-medium;
      display: inline-block;
    }
  }
}
