@import "./DropdownOption/dropdown-option";

.dropdown {
  position: absolute;
  right: 0;
  top: calc(100% + 0.5rem);
  z-index: 1;
  @include shadow;
  background-color: white;
  border-radius: 0.5rem;
  @include flex-justify-start;
  pointer-events: none;
  opacity: 0;
  will-change: opacity, pointer-events;
  width: 100%;

  &.dropdown-open {
    opacity: 1;
    pointer-events: all;
  }
}

@media only screen and (min-width: 500px) {
  .dropdown {
    width: 22.5rem;
  }
}
