@import "./StatusReporting/status-reporting";
@import "./ManageSubscription/manage-subscription";

.account-page-header {
  @include flex-space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.account-items {
  display: grid;
  gap: 1.25rem;

  .account-card {
    background-color: white;
    border-radius: 0.75rem;
    position: relative;
    padding-bottom: calculate-rem(69px);

    .account-card-header {
      padding: 0.75rem 1.25rem 1rem;

      .account-card-header-heading-light {
        color: $grey-medium;
        font: 500 1rem/1.625rem $font;
      }

      .account-card-header-text {
        margin-top: 0.5rem;
        display: block;
        color: $grey-dark;
      }
    }

    .account-card-footer {
      // height: 69px
      @include flex-justify-start;
      border-top: 1px solid $grey-lighter;
      padding: 0.75rem 1.25rem;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      .button {
        margin-right: 0.5rem;
      }
    }
  }
}

.account-items-loader {
  background-color: white;
  border-radius: 0.75rem;

  &.account-items-loader-patients {
    min-height: calculate-rem(286px);
  }

  &.account-items-loader-subscription {
    min-height: calculate-rem(204px);
  }

  &.account-items-loader-addresses {
    min-height: calculate-rem(191px);
  }
}

@media only screen and (min-width: 720px) {
  .account-items {
    grid-template-columns: 1fr 1fr;
    gap: 1.75rem;

    .account-card {
      padding-bottom: calculate-rem(81px);

      .account-card-header {
        padding: 1rem 1.5rem 1.25rem;
      }

      .account-card-footer {
        // height: 81px
        padding: 1rem 1.5rem;

        .button {
          margin-right: 0.75rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1100px) {
  .account-page-header {
    margin-bottom: 0.75rem;
  }

  .account-items {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .account-items-loader {
    &.account-items-loader-patients {
      min-height: calculate-rem(326px);
    }

    &.account-items-loader-subscription {
      min-height: calculate-rem(184px);
    }

    &.account-items-loader-addresses {
      min-height: calculate-rem(219px);
    }
  }
}

@import "./AddNewButton/add-new-button";
