@import "./SidebarItem/sidebar-item";

.sidebar {
  @include animation-preset;
  background-color: $grey-darker;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  top: 0;
  padding: 0 1.25rem;
  z-index: 997;
  transform: translate3d(-100%, 0, 0);
  will-change: transform;
  transition: transform 500ms ease;
  max-width: 22.5rem;

  .sidebar-mobile-hide-button {
    @include flex-justify-start;
    padding: 0.5rem 0;
  }

  &.sidebar-active {
    transform: translate3d(0, 0, 0);
  }

  .sidebar-logo {
    display: none;
  }

  .sidebar-item-group {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 900px) {
  .sidebar {
    width: 15rem;
    height: 100%;
    padding: 0.625rem 0.5rem;
    transform: translate3d(0, 0, 0);
    transition: none;

    .sidebar-logo {
      display: block;
      height: 2.5rem;
      margin: 0 1.5rem 3rem 1.5rem;
    }

    .sidebar-mobile-hide-button {
      display: none;
    }

    .sidebar-item-group {
      grid-template-columns: 1fr;
    }
  }
}
