.add-new-button {
  @include button-preset;
  @include flex-justify-center;
  align-items: center;
  border-radius: 0.75rem;
  background-color: $grey-lighter;
  min-height: calculate-rem(260px);

  &:focus {
    background-color: #e3e4e6;
  }

  @media (hover: hover) {
    &:hover {
      background-color: #e3e4e6;
    }
  }

  .add-new-button-content {
    display: block;

    .icon-plus {
      font-size: 1.5rem;
      color: $grey-medium;
    }

    .add-new-button-content-text {
      display: block;
      width: 100%;
      font: 500 1rem/1.5rem $font;
      color: $grey-dark;
    }
  }
}

@media only screen and (min-width: 900px) {
  .add-new-button {
    .add-new-button-content {
      .icon-plus {
        font-size: 2rem;
      }

      .add-new-button-content-text {
        margin-top: 0.125rem;
        font-size: 1.125rem;
      }
    }
  }
}

@media only screen and (min-width: 1100px) {
  .add-new-button {
    min-height: calculate-rem(296px);
  }
}
