@import "./DiagnosticsUpgradeHint/diagnostics-upgrade-hint";

.appointment-info {
  text-align: center;
  margin-top: 1.5rem;

  .input-select-result {
    max-width: 17.5rem;
    margin: 0 auto;

    @supports (width: max-content) {
      width: max-content;
    }

    .label {
      justify-content: center;
    }
  }
}

// using px values because progressCricle values set in JavaScript (not rems)

.timer {
  margin: 0 auto;
  width: 200px;

  .timer-single {
    position: relative;
    width: 200px;
    height: 200px;

    .progress-circle {
      .progress-circle-ring {
        circle {
          transition: stroke-dashoffset 0.35s;
          transform: rotate(-90deg);
          transform-origin: 50% 50%;
        }
      }
    }

    .timer-single-metric {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .timer-single-metric-unit {
        width: calculate-rem(100px);
        text-align: center;
        font: 600 4.5rem/4.5rem $font;
        color: $grey-darkest;
        display: block;
      }

      .timer-single-metric-name {
        width: calculate-rem(100px);
        text-align: center;
        font: 400 1.75rem/1.75rem $font;
        color: $grey-dark;
        display: block;
      }
    }

    .timer-single-background {
      width: 188px;
      height: 188px;
      border-radius: 50%;
      border: 4px solid $grey-lightest;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
}

@media only screen and (min-width: 600px) {
  .timer {
    width: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
