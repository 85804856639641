@import "./ReportCondition/report-condition";

.report {
  border: 1px solid $grey-lighter;
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;

  &.report-past-report {
    margin-top: 0.75rem;
  }

  .report-header {
    margin-bottom: 0.75rem;

    .report-header-period {
      margin-bottom: 0.5rem;

      .report-header-period-title {
        margin-bottom: 0.5rem;
      }
    }

    .tag {
      margin: 0;
    }
  }
}

@media only screen and (min-width: 900px) {
  .report {
    padding: 1rem 1.25rem;

    &.report-past-report {
      margin-top: 1rem;
    }

    .report-header {
      @include flex-space-between;
      align-items: center;

      .report-header-period {
        @include flex-justify-start;
        align-items: center;
        margin-bottom: 0;

        .report-header-period-title {
          margin: 0 0.5rem 0 0;
        }
      }
    }
  }
}
