.plan {
  &.plan-most-popular {
    .plan-details {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .plan-most-popular-details {
    background-color: $grey-lighter;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    font: 500 0.875rem/1.25rem $font;
    color: $grey-dark;
    padding: 0.5rem 1rem;
    text-align: center;
  }

  .plan-details {
    border: 1px solid $grey-lighter;
    border-radius: 0.5rem;

    .plan-details-header {
      border-bottom: 1px solid $grey-lighter;
      padding: 1rem 1.25rem;

      .plan-details-header-title {
        font: 500 1rem/1.25rem $font;
        color: $grey-darkest;
      }

      .plan-details-header-description {
        margin: 0.5rem 0;
      }

      .plan-details-header-price {
        margin-bottom: 1rem;

        .plan-details-header-price-value {
          font: 600 2rem/2.75rem $font;
          color: $grey-darkest;
          letter-spacing: 0;
          margin-right: 0.125rem;
        }

        .plan-details-header-price-period {
          color: $grey-dark;
          letter-spacing: 0;
        }
      }
    }

    .plan-details-body {
      padding: 1rem 1.25rem;
    }
  }
}

@media only screen and (min-width: 1100px) {
  .plan {
    height: 100%;
    padding-top: 2.5rem;

    &.plan-most-popular {
      padding-top: 0;
      height: calc(100% - 2.5rem);
    }

    .plan-most-popular-details {
      font: 500 1rem/1.5rem $font;
    }

    .plan-details {
      height: 100%;

      .plan-details-header {
        padding: 1.25rem 1.5rem;

        .plan-details-header-title {
          font-size: 1.125rem;
          line-height: 1.5rem;
        }

        .plan-details-header-description {
          margin: 0.75rem 0;
          height: 3.75rem;
        }
      }

      .plan-details-body {
        padding: 1.25rem 1.5rem;
      }
    }
  }
}
