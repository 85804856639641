.slot {
  @include button-preset;
  display: block;
  background-color: white;
  font: 500 0.875rem/1.25rem $font;
  color: $grey-dark;
  will-change: background-color;
  border-radius: 0.5rem;
  border: 1px solid $grey-lighter;
  padding: calculate-rem(9px);

  &.slot-selected {
    background-color: $orange-medium;
    color: white;
    border-color: $orange-medium;

    @media (hover: hover) {
      &:hover {
        background-color: $orange-medium;
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: $grey-lightest;
    }
  }
}
