.card {
  background-color: white;
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;

  .card-body {
    margin-top: 0.75rem;
    display: grid;
    gap: 1rem;
  }
}

@media only screen and (min-width: 900px) {
  .card {
    padding: 1rem 1.25rem;

    .card-body {
      margin-top: 1rem;
    }
  }
}

@media only screen and (min-width: 1100px) {
  .card {
    &.card-columns-2,
    &.card-columns-3 {
      .card-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }

    .card-body {
      gap: 1.25rem 2rem;
    }
  }
}

@media only screen and (min-width: 1350px) {
  .card {
    &.card-columns-3 {
      .card-body {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}
