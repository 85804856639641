.tab {
  @include button-preset;
  display: block;
  cursor: pointer;
  font: 500 0.875rem/1.25rem $font;
  color: $grey-dark;
  letter-spacing: 0.4px;
  padding: 0.625rem 0.75rem;
  text-decoration: none;
  text-align: center;
  border-radius: 0.5rem;
  margin-right: 0.5rem;

  &:focus {
    background-color: white;
  }

  @media (hover: hover) {
    &:hover {
      background-color: white;
    }
  }

  &.active {
    background-color: white;
  }
}
