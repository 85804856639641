.input-date-picker {
  .react-date-picker {
    width: 100%;
    display: block;

    .react-date-picker__wrapper {
      appearance: none;
      border: 1px solid $grey-lighter;
      border-radius: 0.25rem;
      font: 400 1rem/1.5rem $font;
      letter-spacing: 0.4px;
      color: $grey-darkest;
      padding: calculate-rem(3px) 0.25rem calculate-rem(3px) 0.875rem;

      .react-date-picker__inputGroup {
        .react-date-picker__inputGroup__leadingZero {
          display: inline-block;
          vertical-align: baseline;
        }

        .react-date-picker__inputGroup__input {
          line-height: 1.5rem;
        }
      }

      .react-date-picker__calendar-button {
        padding: 0.5rem;
        background-color: white;
        will-change: background-color;
        border-radius: 50%;

        @media (hover: hover) {
          &:hover {
            background-color: $grey-lightest;
          }
        }

        .icon-calendar {
          font-size: 1.5rem;
          color: $grey-medium;
        }
      }
    }

    .react-date-picker__calendar {
      width: calculate-rem(304px);

      @media only screen and (min-width: 375px) {
        --column-width: 2.25rem;
        --gap-width: 0.25rem;
      }

      @media only screen and (min-width: 400px) {
        width: calculate-rem(356px);
        --column-width: 2.5rem;
        --gap-width: 0.5rem;
      }

      .react-calendar {
        border-radius: 0.5rem;
        padding: 0.25rem 0.875rem 0.5rem 0.875rem;
        border: 1px solid $grey-lighter;
        width: calculate-rem(304px);

        @media only screen and (min-width: 375px) {
          --column-width: 2.25rem;
          --gap-width: 0.25rem;
        }

        @media only screen and (min-width: 400px) {
          width: calculate-rem(356px);
          --column-width: 2.5rem;
          --gap-width: 0.5rem;
        }

        .react-calendar__navigation {
          margin-bottom: 0;
        }

        .react-calendar__viewContainer {
          .react-calendar__month-view__days {
            .react-calendar__tile {
              @media only screen and (min-width: 375px) {
                padding: 0.5rem;
              }
              @media only screen and (min-width: 400px) {
                padding: 0.625rem;
              }
            }
          }
        }
      }
    }
  }
}
