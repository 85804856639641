.input-button-list-options {
  padding: calculate-rem(3px) 0;
  @include flex-justify-start;

  &.input-button-list-options-error {
    .input-button-list-option {
      border-color: $red-medium;
    }
  }

  .input-button-list-options-placeholder {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0.5rem 0.875rem;
    border: 1px solid $grey-lighter;
    color: $grey-light;
    background-color: white;
    border-radius: 0.25rem;
  }

  .input-button-list-option {
    @include button-preset;
    display: block;
    border: 1px solid $grey-lighter;
    border-right: none;
    background-color: white;
    color: $grey-medium;
    padding: 0.625rem 1rem;
    font: 500 1rem/1.25rem $font;
    letter-spacing: 0.4px;

    &:first-of-type {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    &:last-of-type {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-right: 1px solid $grey-lighter;
    }

    &:focus {
      background-color: $grey-lightest;
      color: $grey-darker;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $grey-lightest;
        color: $grey-darker;
      }
    }

    &.input-button-list-option-active {
      background-color: $orange-medium;
      color: white;
      border-color: $orange-medium;
    }
  }
}
