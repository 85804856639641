.plan-payment-form-title {
  margin-bottom: 0.75rem;

  &.plan-payment-form-title-sign-up {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.plan-payment-form-card-sign-up {
  grid-column: span 2;
}

.plan-payment-form-disclaimer {
  margin-top: 1rem;
}

.plan-payment-form-coupon {
  padding-bottom: 1.5rem;

  .plan-payment-form-coupon-succeeded {
    .button {
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (min-width: 1100px) {
  .plan-payment-form-title {
    margin-bottom: 1rem;

    &.plan-payment-form-title-sign-up {
      font-size: 1.125rem;
      margin-bottom: 1.125rem;
    }
  }

  .plan-payment-form-disclaimer {
    margin-top: 1.25rem;
  }
}
