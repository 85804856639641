.form-card {
  background-color: white;
  border-radius: 0.75rem;
  padding: 1.25rem 1.5rem 1.5rem 1.5rem;
  margin: 1.25rem auto;
  max-width: calc(100% - 2.5rem);

  .form-card-confirmation {
    font-size: 1.75rem;
    display: block;
    text-align: center;
    color: $green-medium;
    margin-bottom: 0.5rem;
  }

  .form-card-heading-central {
    display: block;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .form-card-heading-left {
    margin-bottom: 0.5rem;
  }

  .form-card-paragraph {
    line-height: 1.5rem;
    margin-bottom: 1rem;

    &.form-card-paragraph-extra-margin-bottom {
      margin-bottom: 1.5rem;
    }
  }
}

.form-billing-details {
  max-width: 38rem;
}

.form-card-small {
  background-color: white;
  border-radius: 0.75rem;
  padding: 1rem 1.25rem;
  max-width: 27.5rem;
}

@media only screen and (min-width: 600px) {
  .form-card {
    margin-top: 3rem;
    margin-bottom: 3rem;
    max-width: 24rem;
  }
}

@media only screen and (min-width: 1024px) {
  .form-card {
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding: 2rem;
  }

  .form-card-small {
    padding: 1.25rem 1.5rem;
  }
}

@media only screen and (min-width: 1100px) {
  .form-card {
    .form-card-paragraph {
      font-size: 0.875rem;
    }
  }
}
