@import "./ChoosePlanModal/choose-plan-modal";

.choose-plan-header {
  border-radius: 0.5rem;
  border: 1px solid $grey-lighter;
  padding: 0.75rem 1rem 1rem;
  margin: 1.5rem 0;
}

@media only screen and (min-width: 1100px) {
  .choose-plan-header {
    padding: 0.75rem 1.25rem 1rem;
  }
}
